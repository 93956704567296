import get from 'lodash/get';

import { getByPostalCode } from '../api/Shops';

import { formatUnixDate } from './dayjs';
import { getOrFetchExpressTimeSlots } from './timeslots';

const NOT_SUPPORTED_REGION_TEXT = 'Δυστυχώς η περιοχή σου δεν καλύπτεται ακόμα';

function getEasyTimeslotsData(shops) {
  if (shops.error) {
    return [undefined, NOT_SUPPORTED_REGION_TEXT];
  }
  const timeslots = get(shops, '[0].timeSlots', []);
  const slot = timeslots.find(t => t.isAvailable);
  if (!slot) {
    const easyDeliverySlotsText = 'Δεν υπάρχουν διαθέσιμες ώρες παράδοσης.';
    return [shops, easyDeliverySlotsText];
  }
  const start = formatUnixDate(slot.timeSlotStart, 'dddd, DD/MM, HH:mm');
  const end = formatUnixDate(slot.timeSlotEnd, 'HH:mm');

  const easyDeliverySlotsText = `Το συντομότερο, ${start} - ${end}`;
  return [shops, easyDeliverySlotsText];
}

function getExpressTimeslotsData(data) {
  if (!data?.extraInfo?.deliversPostalCode) {
    return [data, NOT_SUPPORTED_REGION_TEXT];
  }
  const timeslots = get(data, 'timeslots', []);
  const slot = timeslots.find(t => {
    return t.isAvailable;
  });
  if (!slot) {
    const expressDeliverySlotsText = 'Είμαστε πλήρεις, ραντεβού ξανά το πρωί!';
    return [data, expressDeliverySlotsText];
  }

  const start = formatUnixDate(slot.timeSlotStart, 'dddd, DD/MM, HH:mm');
  const end = formatUnixDate(slot.timeSlotEnd, 'HH:mm');
  const expressDeliverySlotsText = `Το συντομότερο, ${start} - ${end}`;
  return [data, expressDeliverySlotsText];
}

export async function fetchTimeslots(postalCode) {
  const shops = await getByPostalCode(postalCode);
  const [easyTimeslots, easyMessage] = getEasyTimeslotsData(shops);

  const expressData = await getOrFetchExpressTimeSlots(postalCode);
  const [expressTimeslots, exprxessMessage] = getExpressTimeslotsData(expressData);

  return [easyTimeslots, easyMessage, expressTimeslots, exprxessMessage];
}

export function getTakeAwayMessage(timeSlots) {
  const noAvailableTimeSlotsTakeAway = 'Δεν υπάρχουν διαθέσιμες ώρες παράδοσης.';

  if (!timeSlots) return noAvailableTimeSlotsTakeAway;
  const firstAvaiableTimeslot = timeSlots.find(t => t.isAvailable);
  if (!firstAvaiableTimeslot) return noAvailableTimeSlotsTakeAway;
  const start = formatUnixDate(firstAvaiableTimeslot.timeSlotStart, 'dddd, DD/MM, HH:mm');
  const end = formatUnixDate(firstAvaiableTimeslot.timeSlotEnd, 'HH:mm');
  const takeAwayDeliverySlotsText = `Το συντομότερο, ${start} - ${end}`;
  return takeAwayDeliverySlotsText;
}

export default { fetchTimeslots, getTakeAwayMessage };
