import { getTimeslotsForTakeAwayShop, getTimeslots } from '../api/Shops';
import { TakeAwayTimeslotsStore, ExpressTimeslotsStore } from '../store/timeslots';

export function getOrFetchTakeAwayTimeSlots(shop) {
  if (TakeAwayTimeslotsStore.isFetched()) {
    return TakeAwayTimeslotsStore.getValue();
  }

  return updateTakeAwayTimeslots(shop);
}

export async function updateTakeAwayTimeslots(shop) {
  const timeslots = await getTimeslotsForTakeAwayShop(shop);

  TakeAwayTimeslotsStore.update(timeslots);

  return timeslots;
}

export function getOrFetchExpressTimeSlots(postalCode) {
  if (ExpressTimeslotsStore.isFetched()) {
    return ExpressTimeslotsStore.getValue();
  }

  return updateExpressTimeslots(postalCode);
}

export async function updateExpressTimeslots(postalCode) {
  const timeslots = await getTimeslots(postalCode);

  ExpressTimeslotsStore.update(timeslots);

  return timeslots;
}

export default { getOrFetchTakeAwayTimeSlots, getOrFetchExpressTimeSlots, updateTakeAwayTimeslots };
