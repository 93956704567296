import { BehaviorSubject } from 'rxjs';
import { map, skipUntil } from 'rxjs/operators';

import { getProductsWithDetails } from '../api/Products';

const FETCH_STATUSES = {
  UNFETCHED: 'UNFETCHED',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
};

const subjectProducts = new BehaviorSubject([]);
const statusSub = new BehaviorSubject(FETCH_STATUSES.UNFETCHED);

const activeProduct = new BehaviorSubject('');
const renderedProduct = new BehaviorSubject({});
const calledProductCategories = new BehaviorSubject(1);

export const ActiveProduct = {
  update(id) {
    activeProduct.next(id);
  },
  subscribe(setState) {
    return activeProduct.subscribe(setState);
  },
  getValue() {
    return activeProduct.value;
  },
};

export const CalledProductCategories = {
  update(id) {
    calledProductCategories.next(id);
  },
  subscribe(setState) {
    return calledProductCategories.subscribe(setState);
  },
  getValue() {
    return calledProductCategories.value;
  },
};

export const RenderedProducts = {
  update(products) {
    renderedProduct.next(products);
  },
  subscribe(setState) {
    return renderedProduct.subscribe(setState);
  },
  getValue() {
    return renderedProduct.value;
  },
};

export const ProductsStore = {
  update(products) {
    statusSub.next(FETCH_STATUSES.FETCHED);
    subjectProducts.next(products);
    return products;
  },
  subscribe(setState) {
    return subjectProducts.subscribe(setState);
  },
  getValue() {
    return subjectProducts.value;
  },

  /**
   * If fetching, return when response is ready
   * If fetched, return data from the memory
   * If fetched or (fetched & force update)
   * requests data from server
   * @param {Boolean} forceUpdate
   * @returns products
   */
  async getOrFetch(forceUpdate = false) {
    if (statusSub.getValue() === FETCH_STATUSES.FETCHING) {
      return statusSub
        .pipe(
          skipUntil(v => v === FETCH_STATUSES.FETCHED),
          map(() => subjectProducts.getValue())
        )
        .toPromise();
    }

    if (!forceUpdate && statusSub.getValue() === FETCH_STATUSES.FETCHED) {
      return ProductsStore.getValue();
    }
    statusSub.next(FETCH_STATUSES.FETCHING);

    const products = await getProductsWithDetails();
    return ProductsStore.update(products.products);
  },
};

export default { ProductsStore };
