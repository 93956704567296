import { MdLocationPin } from 'react-icons/md';

import { PrimaryButton } from '../Buttons/Buttons';

import styles from './DeliveryPrompt.module.scss';

export default function DeliveryPrompt({ onClickButton }) {
  return (
    <div className={styles.promptContainer}>
      <div className={styles.instruction}>
        <MdLocationPin className={styles.icon} />
        <p className={styles.promptText}>
          Πες μας τη διεύθυνσή σου για να δεις με ποια online υπηρεσία μας θα σε εξυπηρετήσουμε!
        </p>
      </div>
      <PrimaryButton onClick={onClickButton} data-cy="coverage">
        Δες πώς εξυπηρετείσαι
      </PrimaryButton>
    </div>
  );
}
