import { BehaviorSubject } from 'rxjs';

const subjectTakeAwayTimeslotsFetched = new BehaviorSubject(false);
const subjectExpressTimeslotsFetched = new BehaviorSubject(false);

export const TakeAwayTimeslotsFetched = {
  update: fetched => {
    subjectTakeAwayTimeslotsFetched.next(fetched);
  },
};

export const TakeAwayTimeslotsStore = {
  subject: new BehaviorSubject([]),
  update: timeslots => {
    TakeAwayTimeslotsStore.subject.next(timeslots);
    TakeAwayTimeslotsFetched.update(true);
  },
  subscribe: setState => TakeAwayTimeslotsStore.subject.subscribe(setState),
  getValue: () => TakeAwayTimeslotsStore.subject.value,
  isFetched: () => subjectTakeAwayTimeslotsFetched.value,
};

export const ExpressTimeslotsFetched = {
  update: fetched => {
    subjectExpressTimeslotsFetched.next(fetched);
  },
};

export const ExpressTimeslotsStore = {
  subject: new BehaviorSubject([]),
  update: timeslots => {
    ExpressTimeslotsStore.subject.next(timeslots);
    ExpressTimeslotsFetched.update(true);
  },
  subscribe: setState => ExpressTimeslotsStore.subject.subscribe(setState),
  getValue: () => ExpressTimeslotsStore.subject.value,
  isFetched: () => subjectExpressTimeslotsFetched.value,
};

export default {
  TakeAwayTimeslotsStore,
  ExpressTimeslotsStore,
  TakeAwayTimeslotsFetched,
  ExpressTimeslotsFetched,
};
