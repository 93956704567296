import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import { useState, useEffect, Suspense, startTransition } from 'react';
import { distinctUntilChanged } from 'rxjs/operators';

import { ValuesToBeUpdated } from '../../store/cart';
import DeliveryMode from '../../store/deliveryMode';
import { IsAddressRegistered, PostalCode, IsCheckAddressModal } from '../../store/isAddressRegistered';
import ServiceOptions from '../../store/serviceInfo';
import { Shop } from '../../store/shop';
import User from '../../store/user';
import { getTakeAwayMessage, fetchTimeslots } from '../../utils/expressMode';
import CUSTOM_STYLES from '../../utils/modalStyle';
import { updateTakeAwayTimeslots } from '../../utils/timeslots';

import styles from './DeliveryBanner.module.scss';
import DeliveryPrompt from './DeliveryPrompt';

const NOT_SUPPORTED_REGION_TEXT = 'Δυστυχώς η περιοχή σου δεν καλύπτεται ακόμα';
const NOT_AVAILABLE_TIMESLOTS_TEXT = 'Δεν υπάρχουν διαθέσιμες ώρες παράδοσης.';
const FULL_CAPACITY_TIMESLOTS_TEXT = 'Είμαστε πλήρεις, ραντεβού ξανά το πρωί!';

const CheckAddressView = dynamic(() => import('../GenericModal/CheckAddressView/CheckAddressView'), {
  suspense: true,
});

const DeliveryOptions = dynamic(() => import('./DeliveryOptions'), {
  suspense: true,
});
const GenericModal = dynamic(() => import('../GenericModal/GenericModal'), {
  suspense: true,
});

function DeliveryBanner() {
  const [isAddressRegistered, setIsAddressRegistered] = useState();
  const [isCheckAddressModalOpen, setIsCheckAddressModalOpen] = useState(false);

  async function fetchDeliveryMethods(postalCodeInput) {
    const [easyTimeslots, _easyMessage, expressTimeslots, _expressMessage] = await fetchTimeslots(postalCodeInput);
    ServiceOptions.setResExpress(expressTimeslots, _expressMessage);
    if (_expressMessage === NOT_SUPPORTED_REGION_TEXT || _expressMessage === FULL_CAPACITY_TIMESLOTS_TEXT) {
      ServiceOptions.setValidAddressExpress(false);
    } else {
      ServiceOptions.setValidAddressExpress(true);
    }
    ServiceOptions.setResEasy(easyTimeslots, _easyMessage);
    if (_easyMessage === NOT_SUPPORTED_REGION_TEXT || _easyMessage === NOT_AVAILABLE_TIMESLOTS_TEXT) {
      ServiceOptions.setValidAddressEasy(false);
    } else {
      ServiceOptions.setValidAddressEasy(true);
    }
  }

  async function selectStore(store) {
    const { timeslots } = await updateTakeAwayTimeslots(store._id);
    const takeAwayMsg = getTakeAwayMessage(timeslots);
    ServiceOptions.setResTakeAway(timeslots, takeAwayMsg);
    const prevService = DeliveryMode.getValue();
    const prevShop = Shop.getValue();
    ValuesToBeUpdated.updateService(prevService);
    ValuesToBeUpdated.updateShop(prevShop?.takeAwayShop);
    Shop.setTakeAwayShop(store);
    DeliveryMode.update('takeAway');
    if (takeAwayMsg === NOT_AVAILABLE_TIMESLOTS_TEXT) {
      ServiceOptions.setValidTakeAway(false);
      return;
    }
    ServiceOptions.setValidTakeAway(true);
  }

  useEffect(() => {
    async function fetchTimeslotsTakeAway() {
      const currentShop = Shop.getValue();
      ServiceOptions.setValidTakeAway(true);
      if (!currentShop?.takeAwayShop) return;
      const { timeslots } = await updateTakeAwayTimeslots(currentShop?.takeAwayShop._id);
      const takeAwayMsg = getTakeAwayMessage(timeslots);
      ServiceOptions.setResTakeAway(timeslots, takeAwayMsg);
      if (takeAwayMsg === NOT_AVAILABLE_TIMESLOTS_TEXT) {
        ServiceOptions.setValidTakeAway(false);
      }
    }
    fetchTimeslotsTakeAway();
  }, []);

  useEffect(() => {
    PostalCode.lazyInit();

    const isCheckAddressModal$ = IsCheckAddressModal.subscribe(setIsCheckAddressModalOpen);
    const isAddressRegistered$ = IsAddressRegistered.subscribe(hasRegisterAddress => {
      startTransition(() => {
        setIsAddressRegistered(hasRegisterAddress);
      });
    });
    const postalCode$ = PostalCode.subject.pipe(distinctUntilChanged()).subscribe(async value => {
      const _user = User.getValue();
      if (value && (!_user || isEmpty(_user))) {
        fetchDeliveryMethods(value);
      }
      if (value && _user) {
        fetchDeliveryMethods(value);
      }
    });

    return () => {
      isAddressRegistered$.unsubscribe();
      isCheckAddressModal$.unsubscribe();
      postalCode$.unsubscribe();
    };
  }, []);

  const modal = {
    isOpen: isCheckAddressModalOpen,
    closeModal: () => IsCheckAddressModal.update(false),
    customStyles: {
      overlay: {
        ...CUSTOM_STYLES.overlay,
      },
      content: {
        ...CUSTOM_STYLES.content,
        width: '33.875rem',
        maxWidth: '100%',
      },
    },
    navigation: [
      <h3 key="checkAddressHeader" className={styles.modalHeader}>
        Βρες το κατάστημα ή την υπηρεσία σου
      </h3>,
    ],
    view: 'checkAddress',
    body: {
      checkAddress: (
        <Suspense fallback={<p>Loading</p>}>
          <CheckAddressView />
        </Suspense>
      ),
    },
  };

  if (isAddressRegistered) {
    return (
      <Suspense fallback={<p />}>
        <DeliveryOptions
          {...{
            selectStore,
          }}
        />
        <GenericModal modal={modal} />
      </Suspense>
    );
  }
  return (
    <>
      <DeliveryPrompt onClickButton={() => IsCheckAddressModal.update(true)} />
      {isCheckAddressModalOpen && (
        <Suspense fallback={<p />}>
          <GenericModal modal={modal} />
        </Suspense>
      )}
    </>
  );
}

export default DeliveryBanner;
