import Head from 'next/head';

export default function CustomMetaHead({ children, meta }) {
  return (
    <Head>
      <title>
        {meta.title} | {process.env.metaBrandTitle}
      </title>
      {children}
      <meta name="description" content={meta.description} />

      <meta name="keywords" content={meta.keywords} />
      <meta name="robots" content="index, follow" />

      <meta property="og:title" content={`${meta.title} | ${process.env.metaBrandTitle}`} key="title" />
      <meta property="og:url" content={meta.url} />
      <meta property="og:image" content={meta.image} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content={meta.alt} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:site_name" content={meta.alt} />
      <meta property="og:type" content="website" />
      <link rel="canonical" content={meta.url} />
    </Head>
  );
}
