import { BehaviorSubject } from 'rxjs';

const serviceOptions$ = new BehaviorSubject({});

const ServiceOptions = {
  update: selectionMade => {
    serviceOptions$.next(selectionMade);
  },
  subscribe: setState => serviceOptions$.subscribe(setState),
  getValue: () => serviceOptions$.value,
  setResExpress(expressTimeslots, expressMessage) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      expressTimeslots,
      expressMessage,
    });
  },
  setResEasy(easyTimeslots, easyMessage) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      easyTimeslots,
      easyMessage,
    });
  },
  setResTakeAway(takeAwayTimeslots, takeAwayMessage) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      takeAwayTimeslots,
      takeAwayMessage,
    });
  },
  setValidAddressExpress(validAddressExpress) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      validAddressExpress,
    });
  },
  setValidAddressEasy(validAddressEasy) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      validAddressEasy,
    });
  },
  setValidTakeAway(validTakeAway) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      validTakeAway,
    });
  },
  setCity(city) {
    const serviceOptions = ServiceOptions.getValue();
    ServiceOptions.update({
      ...serviceOptions,
      city,
    });
  },
};

export default ServiceOptions;
